<template>
  <div class="iphone">
    <div class="verification">
      <div class="iphone-put">
        <p>
          <van-field v-model="phone" placeholder="请输入手机号" input-align="left" />
          <span>
            <button @click="obtain">获取验证码</button>
          </span>
        </p>
        <p><van-field v-model="code" placeholder="请输入验证码" input-align="left" /></p>
      </div>
      <div class="iphone-foot">
        <button @click="confirm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '../api/login';
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      phone: '13858105116', // 手机号
      code: '', // 验证码
    };
  },
  methods: {
    ...mapMutations({
      setToken: 'common/setToken',
    }),
    obtain() {
      // console.log('获取验证码');
    },
    confirm() {
      // console.log(this.$store);

      getToken({ sign: this.phone }).then(res => {});
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .van-cell {
  padding: 0;
  .van-field__control {
    height: rem(103);
    width: rem(547);
    font-size: rem(28);
  }
}
.iphone {
  width: 100%;
  background: #eeeeee;
  min-height: 100vh;
  .verification {
    width: 100%;
    height: rem(381);
    background-color: #fff;
    padding-left: rem(22);
    padding-right: rem(32);
    .iphone-put {
      p {
        display: flex;
        border-bottom: 1px solid #e2e2e2;
        span {
          height: rem(103);
          line-height: rem(103);
          button {
            background-color: #fff;
            width: rem(149);
            height: rem(48);
            background: #ffffff;
            border: rem(1) solid #3296fa;
            border-radius: rem(8);
            font-size: rem(20);
            color: #3296fa;
            line-height: rem(48);
          }
        }
      }
    }
    .iphone-foot {
      width: 100%;
      height: rem(175);
      // background: red;
      padding-top: rem(44);
      button {
        width: 100%;
        height: rem(88);
        background: #3296fa;
        box-shadow: 0 rem(4) rem(8) 0 rgba(25, 31, 37, 0.12);
        border-radius: rem(6);
        border: 0;
        font-family: PingFangSC-Medium;
        font-size: rem(34);
        color: #ffffff;
        letter-spacing: rem(-0.82);
        text-align: center;
        line-height: rem(88);
      }
    }
  }
}
</style>
